@media(min-width:576px) {
    .element {
        border-top: 5px solid #eee;
        border-right: 5px solid #eee;
        border-left: 5px solid #eee;
    }

    .element1 {
        border-bottom: 5px solid #eee;
        border-right: 5px solid #eee;
        border-left: 5px solid #eee;
    }

    .cursorClass {
        cursor: pointer;
    }

    .fontSize {
        font-size: 1.2rem;
    }

    .blur {
        -webkit-filter: blur(4px);
        -moz-filter: blur(4px);
        -o-filter: blur(4px);
        -ms-filter: blur(4px);
        filter: blur(4px);
        filter: url("https://gist.githubusercontent.com/amitabhaghosh197/b7865b409e835b5a43b5/raw/1a255b551091924971e7dee8935fd38a7fdf7311/blur" .svg);

    }
       
}