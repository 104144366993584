// Pagination
// Pagination-custom

.pagination-custom {
  width: fit-content;
  margin: auto;
  margin-top: 0.2rem;
  .pagination {
    margin: 0em;
    padding: 1em;
  }

  ul {
    display: flex !important;
    appearance: none !important;
    list-style-type: none !important;

    .active {
      a {
        background-color: #000000;
        color: white;
      }
    }

    .disabled {
      a {
        background-color: #dee2e6 !important;
      }
    }

    li {
      margin: 0.4em;
      padding: 0em !important;
      // color: rgba(32, 159, 132, .7) !important;
    }

    a {
      border-radius: 0.25rem !important;
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #007bff;
      background-color: #fff;
      border: 1px solid #dee2e6;
      &:visited {
        color: #007bff;
      }
    }
  }
}

// ################ CMS MANAGEMENT ############

.cms-wrap {
  border-radius: 50px;

  .cms-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #4f4f4f;
    margin-top: 1em;
  }

  .cms-body {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  // override react-draft-wysiwyg editor
  .rdw-editor-main {
    background-color: white;
    border: gray !important;
    height: 300px !important;
    padding: 20px 10px;
  }
}
